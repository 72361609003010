<template>
  <!--柱状图 公共组件-->
  <div style="width: 100%; height: 100%;" id="extant-isolate"></div>
</template>

<script>
import echarts from "../../../lib/echarts";

export default {
  name: "BarGraph",
  components: {},
  data() {
    return {
      dimensions: [],
      source: [],
      xAxisData: [],
      series: []
    }
  },

  mounted() {
    window.onresize = () => {
      let item = document.getElementById("extant-isolate");
      var myChart = echarts.init(item);
      myChart.resize();
    };
  },

  beforeDestroy() {
    window.onresize = null;
  },

  methods: {
    // 获取数据
    getBarGraphList(xAxisData, series) {
      this.xAxisData = xAxisData;
      this.series = series;
      this.initial();
    },

    initial() {
      let item = document.getElementById("extant-isolate");
      var myChart = echarts.init(item);
      myChart.setOption({
        legend: {
          orient: 'vertical',
          bottom: "bottom",
          icon: "circle",
          align: 'left'
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: this.xAxisData
        },
        yAxis: {},
        series: this.series
      })
      myChart.resize();
    }
  }
}
</script>
